<template>
	<div class="placeMarketOld" id="placeMarketOld">
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
	</div>
</template>

<script>
	export default {
		name: 'placeMarketOld'
	}
</script>

<style lang="scss" scoped>
@import "~@/style/common.scss";

.placeMarketOld {
  width: 100%;
  min-height: calc(100vh - 80px);
  padding-top: 20px;
  background: #fff;

  .centerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 1200px;
    padding: 0 20px;

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 46px;
      margin-bottom: 20px;
      color: #666;
      font-size: 16px;
      border: 1px solid #e3e3e5;
      background-color: #fbfcff;

      &-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 126px;
        height: 100%;
        cursor: pointer;

        &.active {
          color: #00b6ff;

          &::after {
            position: absolute;
            width: 100%;
            height: 3px;
            left: 0;
            bottom: 0;
            background-color: #00b6ff;
            content: "";
          }
        }
      }
    }

    .data-box {
      display: flex;
      width: 100%;
    }

    .option-box {
      flex: 1;
      width: 50%;
      padding: 26px;
      margin-right: 20px;
      background-color: #fff;
      border: 1px solid #e3e3e5;

      &:last-child {
        margin-right: 0;
      }

      /deep/ .ivu-form-item-label {
        width: 56px;
        padding-right: 0;
        margin-right: 20px;
        text-align: right;
      }

      .ivu-checkbox-group {
        display: inline-block;
      }

      .com-city-box {
        .line {
          @include flex(flex-start, flex-start);
        }

        .select-box {
          position: relative;

          .place {
            position: absolute;
            top: 0;
            left: 8px;
          }
        }

        /deep/ .multiple-select {
          .ivu-tag {
            display: none;
          }
        }

        .show-city-box {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          span {
            margin-right: 5px;
          }
        }
      }
    }

    .timeTop {
      display: flex;

      .leftTopRadio {
        margin-top: -10px;
        width: 80px;
        margin-left: 22px;
        line-height: 50px;
      }

      .multiple-select {
        white-space: nowrap;

        .ivu-select-selection {
          overflow: hidden;
        }
      }
    }

    .areaTransfer {
      display: flex;
      align-items: center;
      margin-left: 12px;

      .leftTransfer,
      .rightTransfer {
        width: 287px;
        height: 207px;
        border: 1px solid rgba(190, 199, 219, 1);
        border-radius: 5px;
      }

      .leftTransfer {
        background: rgba(246, 249, 255, 0.6);
        padding: 8px;
        box-sizing: border-box;

        .leftTransferBottom {
          display: flex;
          height: 156px;
        }

        .leftRadio {
          flex: 1;
          overflow: auto;

          .ivu-radio-group {
            display: block;
          }

          .ivu-radio-wrapper {
            display: block;
            margin-bottom: 5px;
            color: #333333;
          }
        }

        .rightBox {
          width: 180px;
          height: 156px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(228, 228, 228, 1);
          border-radius: 5px;
          padding: 8px 0;
          box-sizing: border-box;
          overflow: auto;

          .item {
            width: 100%;
            font-size: 16px;
            text-indent: 10px;
            white-space: nowrap;
            cursor: pointer;
            color: #333333;

            .active {
              color: #4373e8;
            }

            &:hover {
              color: #4373e8;
            }
          }
        }
      }

      .leftTransferSearch {
        @include flex;
        margin-bottom: 5px;

        .ivu-input-wrapper {
          width: 220px;
        }

        .btn-all {
          margin-left: auto;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      .centerBtn {
        margin: 0 14px;
        cursor: pointer;
      }

      .rightTransfer {
        border: 1px solid rgba(228, 228, 228, 1);
        overflow: hidden;

        .topBtn {
          width: 100%;
          height: 36px;
          background: #f6f9ff;
          display: flex;
          padding: 0 15px;
          box-sizing: border-box;
          align-items: center;
          justify-content: space-between;

          span {
            color: #999999;
            cursor: pointer;

            &:hover {
              color: #333;
            }
          }
        }

        .itemBox {
          width: 100%;
          padding: 0 15px;
          box-sizing: border-box;
          overflow: auto;
          height: 170px;

          .ivu-tag {
            background: #ededed !important;
          }

          .ivu-tag-checked {
            background: #00b6ff !important;
          }
        }
      }

      &.company {
        margin-left: 0;

        .ivu-radio-wrapper {
          display: block;
          // margin-bottom: 5px;
        }

        .leftTransfer,
        .rightTransfer {
          width: 45%;
          height: 265px;

          .leftTransferBottom {
            height: 200px;
          }

          .leftRadio,
          .rightBox {
            height: 100%;
          }

          .itemBox {
            height: 228px;

            /deep/ .ivu-tooltip {
              width: 100%;

              .ivu-tooltip-rel {
                width: 100%;

                .item {
                  @include flex;
                  width: 100%;

                  span {
                    @include ellipsis;
                    width: 100%;
                  }

                  i {
                    margin-left: 5px;
                    flex-shrink: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .cityManage {
      display: flex;

      .manageBtn {
        flex-shrink: 0;
        text-align: center;
        margin: 10px 0 0 5px;
        // cursor: pointer;
        cursor: not-allowed;

        &:hover {
          color: #00b6ff;
        }
      }

      .rightMangeChoose {
        margin: 20px 0 0;
        white-space: nowrap;
      }
    }

    .btn-box {
      @include flex;
      margin: 18px 0 30px;
      color: #333;
      font-size: 14px;

      .btn {
        @include flex;
        width: 120px;
        height: 40px;
        margin-right: 14px;
        border: 1px solid rgba(228, 228, 228, 1);
        border-radius: 5px;
        cursor: pointer;

        &.check {
          width: 144px;
          color: #fff;
          background-color: #00b6ff;
        }

        &.last-child {
          margin-right: 0;
        }
      }
    }

    .choose-box {
      @include flex(flex-start);
      width: 100%;
      margin-bottom: 20px;

      .title {
        flex-shrink: 0;
        color: #333;
      }

      .des {}
    }

    .table-box {
      width: 100%;

      .top {
        @include flex(flex-end);
        position: relative;
        height: 40px;
        padding-right: 100px;
        background-color: #f0f0f0;
        width: 100%;
        position: relative;

        .download-bar {
          position: absolute;
          top: 7px;
          left: 10px;
        }

        .icon-box {
          width: 90px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0;
          display: flex;

          .icon {
            @include flex;
            position: relative;
            width: 45px;
            height: 100%;
            cursor: pointer;

            &:nth-child(1) {
              &::before {
                @include bgSrc("table.png");
              }

              &.active::before {
                @include bgSrc("table-a.png");
              }
            }

            &:nth-child(2) {
              &::before {
                @include bgSrc("chart.png");
              }

              &.active::before {
                @include bgSrc("chart-a.png");
              }
            }

            &.active {
              background-color: #a2abbb;

              &::after {
                content: "";
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #fff;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
              }
            }

            &::before {
              width: 25px;
              height: 24px;
              content: "";
            }
          }
        }

        .select-box {
          position: relative;

          /deep/ .ivu-select-selected-value {
            display: none;
          }

          .place {
            position: absolute;
            top: 3px;
            left: 10px;
            pointer-events: none;
          }
        }

        .table-opt {
          @include flex;
        }
      }

      .ivu-page {
        position: relative;
        margin: 15px 0;
        text-align: right;
      }
    }

    .canvas-box {
      width: 100%;
      height: 560px;

      canvas {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

